
.heading{
    display: flex;
    width:100%;
    background-color: #D9D9D9;
    height: 3.5rem;
    font-size: 0.8rem;
    font-family: "Inria Sans", serif;
    font-style: normal;
    position: sticky;
    top: 0;
}
.row{
    display: flex;
    width:100%;
    background-color: #F4F4F4;
    height: 4rem;
    font-family: "Inria Sans", serif;
    font-style: normal;
    min-height: 2rem;
        height: fit-content;
        align-items: center;
        justify-content: center;
}

.Hcell{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #000000;
    height: 100%;
    height: 100%;
    border-right: 1.5px solid #a9a9a9;
    font-weight: 700;

}


.Hcell:nth-child(1){
    width: 15%;
    
}
.Hcell:nth-child(2){
    width: 10%
}
.Hcell:nth-child(3){
    width: 20%
}
.Hcell:nth-child(4){
    width: 55%;
    border-right: 0px;
}

.Hcell2{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #000000;
    height: 100%;
    height: 100%;
    border-right: 1.5px solid #a9a9a9;
    font-weight: 700;

}


.Hcell2:nth-child(1){
    width: 15%;
    padding-left: 0.5rem;
    
}
.Hcell2:nth-child(2){
    width: 10%
}.Hcell2:nth-child(3){
    width: 10%
}
.Hcell2:nth-child(4){
    width: 20%
}
.Hcell2:nth-child(5){
    width: 55%;
    border-right: 0px;
}

.cell{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.cell:nth-child(1){
    width: 15%;
    
}
.cell:nth-child(2){
    width: 10%
}
.cell:nth-child(3){
    width: 20%
}
.cell:nth-child(4){
    width: 55%;
    flex-direction: column;
}

.cell2{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 4rem;
}


.cell2:nth-child(1){
    width: 15%;
    padding-left: 0.5rem;
    
}
.cell2:nth-child(2){
    width: 10%
}.cell2:nth-child(3){
    width: 10%
}
.cell2:nth-child(4){
    width: 20%
}
.cell2:nth-child(5){
    width: 55%;
    flex-direction: column;
}

@media screen and (max-width: 1024px){
 .heading{
     font-size: 0.6rem;
     height: 2rem;
     
 }
    .row{
        font-size: 0.6rem;
        
    }

}