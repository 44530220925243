.header{
    background-color: darkslategray;
    color: snow;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.close-button{
    background-color: darkslategray;
    border-color: darkslategrey;
    color: snow;
    border-radius: 10;
}

.close-button:hover{
    background-color: rgb(28, 47, 47);
    border-color: rgb(28, 47, 47);
    color: snow;
    border-radius: 10;
}

.table{
    margin:1rem;
}

.regtangle {
    display: inline-block;
    width: 600px;
    height: 200px;
    background-color: darkslategrey;
    border-radius: 10px;
}
.square-button-style {
    width: 60px;
    height: 60px;
}
.container-style {
    width: 600px;
};
.button-group-style {
    marginTop: 70px
};

.table-container{
    display: grid
}

.costum-stack{
    display: flex;
    flex-wrap:nowrap;
    align-items: center;
    justify-content: center;
    width: fit-content;
}