.search-bar-container{
    padding-top:2vh;
    padding-bottom: 8vh;
    width: 70%;
    margin:auto;
    display:flex;
    flex-direction:column;
    align-items: center;
    min-width: 200px;
}

.empty-seat{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.title{
    font-weight:lighter;
}