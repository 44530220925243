.fm-container{
    height: 100%;
    width: 100%;
    background-color: rgb(0, 25, 27);
    padding-top: 1rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
}

.fm-table-container{
    margin-top: 1.6rem;
}


.table-row{
    display: flex;
    flex-direction: row;
    gap: 10vw;
}

@media (max-width: 1024px) {
    .table-row{
        display: flex;
        flex-direction: row;
        gap: 5vw;
        
    }
    .fm-container{
        height: 100%;
        width: fit-content;
        background-color: rgb(0, 25, 27);
        padding: 2rem;
        padding-top: 1rem;
        padding-bottom: 3rem;
        display: flex;
        justify-content: center;
    }
}