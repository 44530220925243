.btnList{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    gap: 40px;
    color: white;
    height: 100vh;
    width: 100%; 
    flex-wrap: wrap;
}

.option{
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: 10px;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    border: solid 1.5px white;
    transition: all 0.2s ease-in-out  ;
}

.option:hover{
  background-color: rgb(255, 255, 255);
  color: black;
}