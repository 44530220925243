.input-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    padding: 0 15px;
}

input{
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    font-size: 1.25rem;
    font-weight: 500;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
}

#search-icon{
    color: darkslategrey;
}