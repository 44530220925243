.image-sizing{
    width: 100%;
    height: 13rem;
}
.card-button{
    all: unset;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #20242e;
    width: calc(100% - 0.5rem);
    color: antiquewhite
}
.card-button:hover{
    background-color: #20242eb0;
}
.ccc{
    background-color:antiquewhite !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
}
.c{
    background-color: rgb(164, 184, 184);
    border-radius: px;
}
.modal-header{
    background-color:#424a5f;
    color: antiquewhite;
    border-color:#424a5f;
    padding:1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.closeButton{
    color:antiquewhite;
    background-color: antiquewhite;
}
.modal-heading{
    color: antiquewhite;
    margin:0px;
}

.item{
    display: flex;
    width: 90px;
    aspect-ratio: 1/1;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #D9D9D9;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.item:hover{
    background-color: #818181;
}

@media (max-width: 767px) {
    .modal-header{
        margin-bottom:0px;
    }
  }