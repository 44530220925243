:root{
    --seat-height: 50px;
    --seat-width: 90px;
}

.header{
    background-color: darkslategray;
    color: snow;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.close-button{
    background-color: darkslategray;
    border-color: darkslategrey;
    color: snow;
    border-radius: 10;
}

.close-button:hover{
    background-color: rgb(28, 47, 47);
    border-color: rgb(28, 47, 47);
    color: snow;
    border-radius: 10;
}

.rightContainer{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    width: 40%;

}

.leftContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;

}
.tableContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    aspect-ratio: 1/1;
    position: relative;
}
.table{
    background-color: #342E2E;
    width: 100%;
    aspect-ratio: 5/2.3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: snow;
    border-radius: 30px;
    font-size: 3rem;
    font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
}

.seats{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    font-family: "inria", sans-serif;
    font-weight: 600;
}

.seat{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 90px;
    width: 15%;
    height: 50px;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom: 0px !important;


}
.seatUpsideDown{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 90px;
    width: 15%;
    height: 50px;
    padding: 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top: 0px !important;
}

.tableRound{ position: absolute;
    top: calc(0 + var(--seat-height));
    left: calc(0 + var(--seat-height));
    border-radius: 50%; /* Makes table circular */
    background-color: #342e2e;
    width: 35vw ;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: snow;
    border-radius: 50%;
    font-size: 3rem;
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
}

.seatsRound{
    font-family: "inria", sans-serif;
    font-weight: 600;
  
    position: absolute;
    top: calc(50% - var(--seat-height));
    left: calc(50% - var(--seat-width)/2);
    width: 100%;/* Adjust size as needed */
    aspect-ratio: 1/1;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.seatRound{
    font-family: "inria", sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 90px;
    height: 50px;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom: 0px !important;
    position: absolute;
    top: calc(var(--seat-height)/2);
    left: 0;
    
}

.fm-container{
    margin: 0;
    background-color: rgb(23, 23, 23);
}

.fm-div{
    transform: scale(0.8);
}

.order-list-container{
    width: 100%;
}

.wb{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.listContainer{
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    background-color: #F4F4F4;
    width: 90%;
    height: 90%;
    box-shadow: 0px 0px 10px 0px #0000005b;
}
.bar{
    height: 5px;
    width: 30%;
    background-color: #D9D9D9;
    border-radius: 10px;
}

.listHeader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5rem;
    font-family: "Oswald", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    gap: 5px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    background-color: #F4F4F4;
}

.listBody{
    display: flex;
    flex-direction: column;
    justify-content: bottom;
    align-items: bottom;
    height: calc(100%-5rem);
    max-height: calc(100% - 5rem);
    overflow: auto;
}

@media screen and (max-width: 1024px){
    .seat{
        font-size: 0.8rem;
    }
    .seatUpsideDown{
        font-size: 0.8rem;
    }
    .listHeader{
        font-size: 1.5rem;
    }
    .bar{
        width: 20%;
        height: 4px;
    }
    
}