.so-container{
    background-color:rgba(250, 235, 215, 0.212);
    color: antiquewhite;
    width: 40rem;
    margin:2px;
    margin-left: 40px; 
    border-radius: 5px;
}

.item-name{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
}

@media (max-width: 767px) {
    .so-container{
        width: 80vw;
    }
   }