.ShoppingRef{
    height: fit-content;
    padding-bottom: 100vh;
    width: 100%;
    background-color: #1b1b1b;
}
.heading{
   color: royalblue;
   padding: 2rem;
} 
.title{
    margin-left: 2rem;
    color: snow;
}

.wrapper{
    padding:1rem;
    margin: 5px;
    margin-left: 3rem;
    margin-bottom: 2rem;
    background-color: rgb(75, 75, 75);
    width: 15rem;
    border-radius: 10px;
}

.sub{
    color: snow;
}

@media (max-width: 767px) {
    .ShoppingRef{
        height: fit-content;
        padding-bottom: 60px;
        width: 100%;
        background-color: #1b1b1b;
    }
  }