.card{
    margin-right:2.5rem;
    margin-bottom:1rem ;    
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);;
}
.view-order-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    font-size: 0.8rem;
}
.row{
    margin: 1px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.r-con{
    border-color: antiquewhite;
}

.item-name{
    color:antiquewhite;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:rgba(169, 158, 144, 0.504);
    opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:rgba(169, 158, 144, 0.504);
    opacity: 1;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color:rgba(169, 158, 144, 0.504);
    opacity: 1;
}
.oc{
    padding: 1rem;
    padding-bottom: 0;
    color:antiquewhite
}

.oc-sub{
    margin-left: 1rem;
    color: antiquewhite;
}

.oc-credit{
    margin-left: 1rem;
    font-size: 0.8rem;
    color: antiquewhite;
}

@media (max-width: 767px) {
    .card{
        margin-bottom: 15px;
    }
  }
