.results-list{
    width:100%;
    background-color: white;
    display:flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    margin-top: 1rem;
    max-height: 150px;
    overflow-y: scroll;
    font-family: Arial, Helvetica, sans-serif;
}