th.sticky{
    position: sticky;
    top: 0;
}

tr.ao-heading{
        position: sticky;
        top: 0;
        padding: 2rem;
        width:100%;
        background-color: darkslategray;
        color: snow;
}

table.orders{
    border-collapse:separate; 
    border-spacing: 0 0.3em;
    width: 80%;
    
}

td.ao-item-PREPARED{
    background-color: #ffbbc2;
    padding: 10px;
    color: rgb(0, 0, 0);
    font-size: 0.9rem;
}
td.ao-item-UNFINISHED{
    background-color: #ffd12c;
    padding: 10px;
    color: rgb(0, 0, 0);
    font-size: 0.9rem;
}

td.first{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

td.last{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}