.preorder{
    margin:0;
    min-height: 100vh;
    background-color: rgb(27, 27, 27);
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width:100%;
    padding:10px;
    padding-top:12px;
    font-family: "Oswald", serif;
    font-weight: 100;
    color: antiquewhite;
}
.form-header{
    margin-top:1rem;
    color:antiquewhite
}

.form-input{
    width: 100%;
    height: 2.5rem;
    border-radius: 5px;
    border: 1px solid snow;
    color:antiquewhite;
    padding-left: 1rem;
    font-family: 'Courier New', Courier, monospace;
}
.form-input:hover{

}
.form-input:focus{
}
.card-list{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.h{
    margin-top:2rem;

    font-weight: bold;
    color: antiquewhite;
    font-family: 'Courier New', Courier, monospace

}
.no-items{
    background-color:rgba(250, 235, 215, 0.212);
    color: antiquewhite;
    border-radius: 5px;
    padding:1rem;
    width: 13rem;
    margin-top:0;

    font-weight: bold;
    color: antiquewhite;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;

}
.cards-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    margin-top:1.3rem;
    margin-bottom: 1.3rem;
    height: fit-content;
    gap: 1.5rem;
}
.sv-cards-container{
    display: flex;
    flex-direction: row;
    padding-top:1rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: rgb(234, 234, 234);
    border-radius: 10px;
}

.submit-btn{
    width:10rem;
}

.pv-header{
    background-color: #000000;
    font-weight: bold;
    color:white;
}

.pv-item-UNFINISHED{
    background-color: #fad054;
    padding:3px;
    padding-left:1rem;
    border-radius: 5px;
    margin: auto;
    margin-top: 4px;
    margin-bottom: 4px;
}

.pv-item-PREPARED{
    background-color:  #dc3545;
    padding:3px;
    padding-left:1rem;
    border-radius: 5px;
    margin: auto;
    margin-top: 4px;
    margin-bottom: 4px;
    color:white;
}
/* For screens smaller than 768px (portrait phones) */
@media (max-width: 767px) {
    .preorder{
        margin:0;
        height: fit-content;
        padding-bottom: 5vh;
        background-color: #20242e;
    }
    .form-input{
        width: 80%;
    }
    .form-input:hover{
        width: 80%;
    }
    .form-input:focus{
        width: 80%;
    }
    .cards-container{
        width: 100vw;
        flex-wrap: wrap;
    }
  }
  
  /* For screens between 768px and 1024px (landscape phones and smaller tablets) */
