.host-container{
    padding: 4rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgb(22, 22, 22);
}

.sb-container{
    width: 50vw;
}

.host-title{
    color:royalblue;
}
.sub-title{
    margin-top: 1rem;
    color: white;
    font-size: 2rem;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}
.check-in-btn{
    margin-top: 1rem;;
    align-self: center;
    text-align: center;
}